<template>
  <div>
  <TosListVuetify serviceName="yard" entityName="unit-types" :headers="headers" >
    <template #customFilters="slotProps">
      <v-container fluid>
       <v-row>         
          <v-col cols="3">
            <v-select :label="tLabel('Unit sys. subtype')"  v-model="slotProps.data.sysSubtype" :items="sysSubtypes" />
          </v-col>
          <v-col cols="3">           
            <v-text-field  :label="tLabel('Unit type code')" v-model="slotProps.data.unitTypeCode" />
          </v-col>
       </v-row>
      </v-container>
    </template>
    <template v-slot:editF="slotProps">
        <v-container fluid>
          <v-row>           
            <v-col>
              <v-select :label="tLabel('Unit sys. subtype')"  v-model="slotProps.data.sysSubtype" :items="sysSubtypes" :rules="[rules.required]" :error-messages="slotProps.errors.unitType" />
            </v-col>
            <v-col>
              <v-text-field :label="tLabel('Unit type code')"  v-model="slotProps.data.unitTypeCode" :rules="[rules.required]" :error-messages="slotProps.errors.unitTypeCode" />
            </v-col>
            <v-col>
              <v-text-field :label="tLabel('ISO size type')"  v-model="slotProps.data.isoSizeType" :error-messages="slotProps.errors.isoSizeType" />
            </v-col>       
            <v-col>
              <v-select :label="tLabel('Count Type')" v-model="slotProps.data.countType" clearable :items="countTypes" :error-messages="slotProps.errors.countType" />
            </v-col>        
          </v-row>
          <v-row>
            <v-col>
              <v-text-field  :label="tLabel('Height')" v-model="slotProps.data.height" :rules="[rules.numeric]" :error-messages="slotProps.errors.height"  />
            </v-col>
            <v-col>
              <v-text-field  :label="tLabel('Width')" v-model="slotProps.data.width" :rules="[rules.numeric]" :error-messages="slotProps.errors.width"  />
            </v-col>
            <v-col>
              <v-text-field  :label="tLabel('Length')" v-model="slotProps.data.length" :rules="[rules.numeric]" :error-messages="slotProps.errors.length"  />
            </v-col>
            <v-col>
              <v-text-field  :label="tLabel('Tare Mass')" v-model="slotProps.data.tareMass" :rules="[rules.numeric]" :error-messages="slotProps.errors.tareMass"  />
            </v-col>
          </v-row>       
       </v-container>
    </template>
  </TosListVuetify>

</div>

</template>


<script>
import TosListVuetify from '../../../../components/anagrafiche/TosListVuetify';
import FormatsMixins from "../../../../mixins/FormatsMixins";

export default {
  name: "UnitTypes",
  data() {
    return {
      sysSubtypes: [],
      countTypes: [],
      headers: [
        { text: "", value: "action" },
        // { text: "ID", value: "id" },
        { text: `${this.tLabel('Unit sys. subtype')}`, value: "sysSubtype" },
        { text: `${this.tLabel('Unit type code')}`, value: "unitTypeCode" },
        { text: `${this.tLabel('ISO size type')}`, value: "isoSizeType" },
        { text: `${this.tLabel('Count Type')}`, value: "countType" },
        { text: `${this.tLabel('Height')}`, value: "height" },
        { text: `${this.tLabel('Width')}`, value: "width" },
        { text: `${this.tLabel('Length')}`, value: "length" },
        { text: `${this.tLabel('Tare Mass')}`, value: "tareMass" }
      ],
    };
  },
  mounted(){
    this.loadCombo();
  },
  
  methods: {
    async prepareItem(data) {
    },
    async loadCombo() {
      this.sysSubtypes = await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "lookups/unitTypes");
      this.countTypes = await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "lookups/countTypes");
    }    
  },
  components: {TosListVuetify},
  mixins: [FormatsMixins],
};
</script>
